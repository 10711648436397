import { Injectable } from '@angular/core';
import { NewslinePostHttpService } from '@core/services/api/newsline/newsline-post-http.service';
import { ArrayPayload } from '@shared/models/payload.model';
import { QueryParams } from '@shared/models/query-params.model';
import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FetchLikesService, Like } from '../../dialog/likes-dialog/likes-provider';

@Injectable()
export class PostLikesFetchService implements FetchLikesService {
  constructor(private newslinePostHttpService: NewslinePostHttpService) {}

  public like(id: number): Observable<void> {
    return this.newslinePostHttpService.likePostById(id);
  }

  public unlike(id: number): Observable<void> {
    return this.newslinePostHttpService.unlikeNewslinePost(id).pipe(catchError(() => EMPTY));
  }

  public getLikes(id: number, params?: QueryParams): Observable<ArrayPayload<Like>> {
    return this.newslinePostHttpService.getPostLikes(id, params).pipe(catchError(() => EMPTY));
  }
}
