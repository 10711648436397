import { InjectionToken } from '@angular/core';
import { ArrayPayload } from '@shared/models/payload.model';
import { QueryParams } from '@shared/models/query-params.model';
import { Observable } from 'rxjs';

export interface Like {
  id: number;
  first_name: string;
  last_name: string;
  avatar: string;
}

export interface FetchLikesService<T extends Like = Like> {
  getLikes(id: number, params?: QueryParams): Observable<ArrayPayload<T>>;
  like(id: number): Observable<void>;
  unlike(id: number): Observable<void>;
}

export const FETCH_LIKES_SERVICE_TOKEN = new InjectionToken<FetchLikesService>('FetchLikesService');
