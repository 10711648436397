import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { INewslineTopic, NewslinePostHttpService } from '@core/services/api/newsline/newsline-post-http.service';
import {
  POST_FORUM_MEETING_FIRST_MEETING_ANY_DATE_ANSWER_TEXT,
  POST_FORUM_MEETING_FIRST_MEETING_ANY_DATE_VOTE_TEXT,
} from '@modules/newsline/constants/common.const';
import { PostTypeKey, PostVoteType } from '@modules/newsline/enums/keys.enum';
import { DATE_FULL_WITH_MONTH_WORD_AND_TIME } from '@shared/constants/common.const';
import { IFile, IFileDto } from '@shared/models/file.model';
import { IForumFirstMeetingVoteDataDto } from '@shared/models/forums/dto/forum-first-meet.model';
import { KeyValue } from '@shared/models/key-value.model';
import { INewslinePostDto, INewslinePostNew } from '@shared/models/newsline/dto/newsline-post-dto.model';
import { INewslinePostImageDto } from '@shared/models/newsline/dto/newsline-post-image-dto.model';
import { INewslinePostVoteAnswerDto } from '@shared/models/newsline/dto/newsline-post-vote-answer-dto.model';
import { INewslinePostVoteDto } from '@shared/models/newsline/dto/newsline-post-vote-dto.model';
import { INewslinePostComment } from '@shared/models/newsline/view/newsline-post-comment.model';
import { INewslinePostImage } from '@shared/models/newsline/view/newsline-post-image.model';
import { INewslinePostVoteAnswer } from '@shared/models/newsline/view/newsline-post-vote-answer.model';
import { INewslinePostVote } from '@shared/models/newsline/view/newsline-post-vote.model';
import { INewslinePost } from '@shared/models/newsline/view/newsline-post.model';
import { forkJoin, Observable, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { DateService } from '../utils/date.service';
import { NewslinePostCommentService } from './newsline-post-comment.service';
import { NewslinePostFileService } from './newsline-post-file.service';
import { NewslinePostImageService } from './newsline-post-image.service';
import { NewslinePostVoteAnswerService } from './newsline-post-vote-answer.service';
import { NewslinePostVoteService } from './newsline-post-vote.service';
import { ArrayPayload } from '@shared/models/payload.model';
import { QueryParams } from '@shared/models/query-params.model';

@Injectable({
  providedIn: 'root',
})
export class NewslinePostService {
  posts: INewslinePostNew[] = [];
  postsCount: number = 0;
  postsFilteredCount = 0;
  postsLocalCount: number;
  isListPostsLoading = false;

  public isNewslineLoading: boolean = false;

  // Тематики поста
  public newslinePostTopics: INewslineTopic[] = [];

  constructor(
    private authService: AuthService,
    private dateService: DateService,
    private newslinePostHttpService: NewslinePostHttpService,
    private newslinePostImageService: NewslinePostImageService,
    private newslinePostFileService: NewslinePostFileService,
    private newslinePostVoteService: NewslinePostVoteService,
    private newslinePostVoteAnswerService: NewslinePostVoteAnswerService,
    private newslinePostCommentService: NewslinePostCommentService,
  ) {}

  getPostsAndAddToPosts(
    targetPosts?: INewslinePost[],
    params?: KeyValue<string>,
    paramsString?: string,
    setPostsCounts = true,
  ): Observable<INewslinePost[]> {
    return this.newslinePostHttpService.getPosts(params, paramsString).pipe(
      map((response) => {
        if (setPostsCounts) {
          this.postsFilteredCount = response.count;
          this.postsLocalCount = this.posts?.length;
        }

        if (!targetPosts) {
          targetPosts = [];
        }

        response.results.forEach((postDto) => {
          targetPosts.push(this._transformPostDtoToPostView(postDto));
        });

        return targetPosts;
      }),
    );
  }

  public getNewslinePosts(params?: QueryParams): Observable<ArrayPayload<INewslinePostNew>> {
    return this.newslinePostHttpService.getNewslinePosts(params)
  }

  public getNewslineTopics(params?: QueryParams): Observable<ArrayPayload<INewslineTopic>> {
    return this.newslinePostHttpService.getNewslineTopics(params)
  }

  public patchPostMarkAsViewed(id: number): Observable<void> {
    return this.newslinePostHttpService.patchPostMarkAsViewed(id);
  }

   public likeNewslinePost(id: number): Observable<void> {
    return this.newslinePostHttpService.likeNewslinePost(id);
  }

  public unlikeNewslinePost(id: number): Observable<void> {
    return this.newslinePostHttpService.unlikeNewslinePost(id)
  }

  // Инкремент для счетчика sharing post
  public incrementSharingPost(id: number): Observable<void> {
    return this.newslinePostHttpService.incrementSharingPost(id);
  }

  public postReportNewslinePost(id: number, report: {types: string[], comment?: string}): Observable<{types: string[], comment?: string}> {
    return this.newslinePostHttpService.postReportNewslinePost(id, report)
  }

  getPostsWithCommentsAndAttachmentsAndAddToPosts(
    targetPosts?: INewslinePost[],
    params?: KeyValue<string>,
    paramsString?: string,
    setPostsCounts = true,
  ) {
    return this.getPostsAndAddToPosts(targetPosts, params, paramsString, setPostsCounts).pipe(
      mergeMap((posts) => {
        const attachmentsRequests: Array<
          | Observable<INewslinePostImage[]>
          | Observable<IFile[]>
          | Observable<INewslinePostVote>
          | Observable<INewslinePostVoteAnswer[]>
          | Observable<INewslinePostComment[]>
        > = [];

        if (!targetPosts) {
          targetPosts = posts;
        }

        targetPosts.forEach((post) => {
          attachmentsRequests.push(
            this.newslinePostImageService.getImagesByPostIdAndAddToPost(post.id, post),
            this.newslinePostFileService.getFilesByPostIdAndAddToPost(post.id, post),
            this.newslinePostVoteService.getVoteWithAnswersAndAddToPost(post.id, post),
            this.newslinePostCommentService.getCommentsWithAttachmentsByPostIdAndAddToPost(post.id, post),
          );
        });

        if (attachmentsRequests.length) {
          return forkJoin(attachmentsRequests).pipe(map(() => targetPosts));
        }
        return of(targetPosts);
      }),
    );
  }

  getPostById(id: number): Observable<INewslinePost> {
    return this.newslinePostHttpService.getPostById(id).pipe(
      map((postDto) => {
        return this._transformPostDtoToPostView(postDto);
      }),
    );
  }

  getPinnedPostAndAddToPosts(
    targetPosts?: INewslinePost[],
    params?: KeyValue<string>,
    paramsString?: string,
  ): Observable<INewslinePost> {
    const filters = { pinned: 'true' };
    const filtersInString = `pinned=true`;

    params = params ? { ...params, ...filters } : null;
    paramsString = paramsString ? `${paramsString}&${filtersInString}` : filtersInString;

    return this.getPostsWithCommentsAndAttachmentsAndAddToPosts(null, params, paramsString, false).pipe(
      map((pinnedPosts) => {
        const pinnedPost = pinnedPosts[0];
        if (pinnedPost) {
          targetPosts?.unshift(pinnedPost);
        }

        return pinnedPost;
      }),
    );
  }

  createPostAndAddToPosts(
    postToCreate: Partial<INewslinePostDto>,
    targetPosts?: INewslinePost[],
    formToReset?: FormGroup,
  ): Observable<any> {
    return
    // return this.newslinePostHttpService.createPost(postToCreate).pipe(
    //   map((postDto) => {
    //     const createdPost = this._transformPostDtoToPostView(postDto);
    //
    //     if (targetPosts) {
    //       if (targetPosts[0]?.pinned) {
    //         targetPosts.splice(1, 0, createdPost);
    //       } else {
    //         targetPosts.unshift(createdPost);
    //       }
    //     }
    //     formToReset?.reset();
    //
    //     this.postsFilteredCount += 1;
    //     return createdPost;
    //   }),
    // );
  }

  public createNewslinePost(post: Partial<INewslinePostNew>): Observable<INewslinePostNew> {
    return this.newslinePostHttpService.createPost(post);
  }

  createPostWithAttachmentsAndAddToPosts(
    postToCreate: Partial<INewslinePostDto>,
    targetPosts?: INewslinePost[],
    formToReset?: FormGroup,
    images?: INewslinePostImageDto[],
    files?: IFileDto[],
    vote?: INewslinePostVoteDto,
  ): Observable<INewslinePost> {
    return this.createPostAndAddToPosts(postToCreate, targetPosts, formToReset).pipe(
      mergeMap((createdPost) => {
        const targetPost = targetPosts?.find((p) => p.id === createdPost.id) || createdPost;
        const attachmentsRequests = this._getCreateRequestsForPostAttachments(targetPost, images, files, vote);

        if (attachmentsRequests.length) {
          return forkJoin(attachmentsRequests).pipe(
            map(() => {
              return targetPost;
            }),
          );
        }
        return of(targetPost);
      }),
    );
  }

  updatePostByIdAndReplaceInPosts(
    postId: number,
    postDto: Partial<INewslinePostDto>,
    targetPost?: INewslinePost,
    formToReset?: FormGroup,
  ): Observable<INewslinePost> {
    return this.newslinePostHttpService.updatePostById(postId, postDto).pipe(
      map((updatedPostDto) => {
        const updatedPost = this._transformPostDtoToPostView(updatedPostDto);

        if (targetPost) {
          targetPost = Object.assign(targetPost, updatedPost);
        }
        formToReset?.reset();

        return updatedPost;
      }),
    );
  }

  updatePostWithAttachmentsByIdAndReplaceInPosts(
    postId: number,
    postDto: Partial<INewslinePostDto>,
    targetPost: INewslinePost,
    formToReset?: FormGroup,
    imagesToCreate?: INewslinePostImageDto[],
    imagesToDelete?: INewslinePostImageDto[],
    filesToCreate?: IFileDto[],
    filesToDelete?: IFileDto[],
    voteWithAnswersToCreate?: INewslinePostVoteDto,
    voteWithAnswersToUpdate?: INewslinePostVoteDto,
    voteToDelete?: INewslinePostVoteDto,
    answersToDelete?: INewslinePostVoteAnswerDto[],
  ): Observable<INewslinePost> {
    return this.updatePostByIdAndReplaceInPosts(postId, postDto, targetPost, formToReset).pipe(
      mergeMap((updatedPost) => {
        targetPost = Object.assign(targetPost, updatedPost);

        const deleteAttachmentsRequests: Observable<any | any[]>[] = [];
        deleteAttachmentsRequests.push(
          ...this._getDeleteRequestsForPostAttachments(
            targetPost,
            imagesToDelete,
            filesToDelete,
            voteToDelete,
            answersToDelete,
          ),
        );

        const createUpdateAttachmentsRequests: Observable<any | any[]>[] = [];
        createUpdateAttachmentsRequests.push(
          ...this._getCreateRequestsForPostAttachments(
            targetPost,
            imagesToCreate,
            filesToCreate,
            voteWithAnswersToCreate,
          ),
        );
        createUpdateAttachmentsRequests.push(
          ...this._getUpdateRequestsForPostAttachments(targetPost, voteWithAnswersToUpdate),
        );

        if (deleteAttachmentsRequests.length) {
          return forkJoin(deleteAttachmentsRequests).pipe(
            mergeMap(() => {
              return forkJoin(createUpdateAttachmentsRequests).pipe(map(() => updatedPost));
            }),
          );
        }
        if (createUpdateAttachmentsRequests.length) {
          return forkJoin(createUpdateAttachmentsRequests).pipe(map(() => updatedPost));
        }
        return of(updatedPost);
      }),
    );
  }

  deletePostByIdAndRemoveFromPosts(postId: number, targetPosts?: INewslinePostNew[]): Observable<void> {
    const postToDeleteIdx: number = targetPosts?.findIndex((p) => p.id === postId);

    return this.newslinePostHttpService.deletePostById(postId).pipe(
      tap(
        () => {
          if (targetPosts && postToDeleteIdx !== -1) {
            targetPosts.splice(postToDeleteIdx, 1);
          }
        },
      ),
    );
  }

  public deletePostById(postId: number): Observable<void>  {
    return this.newslinePostHttpService.deletePostById(postId)
  }

  likePostByIdAndUpdatePostState(postId: number, targetPost?: INewslinePost): Observable<void> {
    if (targetPost) {
      targetPost.isLikeSwitchLoading = true;
    }

    return this.newslinePostHttpService.likePostById(postId).pipe(
      tap(() => {
        if (targetPost) {
          targetPost.liked_users.push(this.authService.currentUser?.id);
          targetPost.likes_count += 1;
          targetPost.isLikedByCurrentUser = true;
          targetPost.isLikeSwitchLoading = false;
        }
      }),
    );
  }

  unlikePostByIdAndUpdatePostState(postId: number, targetPost?: INewslinePost): Observable<void> {
    if (targetPost) {
      targetPost.isLikeSwitchLoading = true;
    }

    return this.newslinePostHttpService.unlikePostById(postId).pipe(
      tap(() => {
        if (targetPost) {
          targetPost.liked_users = targetPost.liked_users.filter(
            (userId) => userId !== this.authService.currentUser?.id,
          );
          targetPost.likes_count -= 1;
          targetPost.isLikedByCurrentUser = false;
          targetPost.isLikeSwitchLoading = false;
        }
      }),
    );
  }

  getForumFirstMeetingPost(forumId: number): Observable<INewslinePost> {
    return this.newslinePostVoteService.getForumFirstMeetingVote(forumId).pipe(
      mergeMap((vote) => {
        if (vote) {
          return this.getPostById(vote.post).pipe(
            map((post) => {
              post.vote = vote;
              return post;
            }),
          );
        }
        return of({} as INewslinePost);
      }),
    );
  }

  createPostWithForumFirstMeetVote(data: IForumFirstMeetingVoteDataDto, forumId: number): Observable<INewslinePost> {
    const newPost: Partial<INewslinePostDto> = {
      type: +PostTypeKey.Forums,
      forum: forumId,
    };

    const answers: INewslinePostVoteAnswerDto[] = data.dates.map((date) => ({
      answer: date.format(DATE_FULL_WITH_MONTH_WORD_AND_TIME),
    }));

    if (data.anyDateTime) {
      answers.push({
        answer: POST_FORUM_MEETING_FIRST_MEETING_ANY_DATE_ANSWER_TEXT,
      });
    }

    const vote: INewslinePostVoteDto = {
      type: +PostVoteType.FirstMeetingDate,
      question: POST_FORUM_MEETING_FIRST_MEETING_ANY_DATE_VOTE_TEXT,
      answers,
      is_results_hidden: false,
      allow_members_answers: !!data.allow_members_answers,
      post: null,
    };

    return this.createPostWithAttachmentsAndAddToPosts(newPost, null, null, null, null, vote);
  }

  updateForumFirstMeetVote(
    currentVote: INewslinePostVote,
    data: IForumFirstMeetingVoteDataDto,
  ): Observable<INewslinePostVote> {
    const reqs: Observable<any>[] = [];

    const createAnswersReqs = data.dates
      .filter((answerDate) =>
        currentVote.answers.some((a) => a.answer !== answerDate.format(DATE_FULL_WITH_MONTH_WORD_AND_TIME)),
      )
      .map((answerDate) => {
        const answerToCreate: Partial<INewslinePostVoteAnswer> = {
          answer: answerDate.format(DATE_FULL_WITH_MONTH_WORD_AND_TIME),
          poll: currentVote.id,
        };
        return this.newslinePostVoteAnswerService.createAnswerAndAddToVote(answerToCreate);
      });
    reqs.push(...createAnswersReqs);

    const deleteAnswersReqs = currentVote.answers
      .filter((a) =>
        data.dates.some((answerDate) => answerDate.format(DATE_FULL_WITH_MONTH_WORD_AND_TIME) !== a.answer),
      )
      .map((a) => this.newslinePostVoteAnswerService.deleteAnswerByIdAndRemoveFromVote(a.id));
    reqs.push(...deleteAnswersReqs);

    if (
      data.anyDateTime &&
      !currentVote.answers.some((a) => a.answer === POST_FORUM_MEETING_FIRST_MEETING_ANY_DATE_ANSWER_TEXT)
    ) {
      const answerToCreate: Partial<INewslinePostVoteAnswer> = {
        answer: POST_FORUM_MEETING_FIRST_MEETING_ANY_DATE_ANSWER_TEXT,
        poll: currentVote.id,
      };
      const createAnswersReq = this.newslinePostVoteAnswerService.createAnswerAndAddToVote(answerToCreate);
      reqs.push(createAnswersReq);
    } else if (
      !data.anyDateTime &&
      currentVote.answers.some((a) => a.answer === POST_FORUM_MEETING_FIRST_MEETING_ANY_DATE_ANSWER_TEXT)
    ) {
      const answerToDelete = currentVote.answers.find(
        (a) => a.answer === POST_FORUM_MEETING_FIRST_MEETING_ANY_DATE_ANSWER_TEXT,
      );
      reqs.push(this.newslinePostVoteAnswerService.deleteAnswerByIdAndRemoveFromVote(answerToDelete.id));
    }

    const vote: Partial<INewslinePostVote> = {
      allow_members_answers: !!data.allow_members_answers,
    };

    return this.newslinePostVoteService.updateVoteByIdAndReplaceInPost(currentVote.id, vote).pipe(
      mergeMap((updatedVote) => {
        if (reqs.length) {
          return forkJoin(reqs).pipe(map(() => updatedVote));
        }
        return of(updatedVote);
      }),
    );
  }

  pinPostByIdAndUpdatePostsState(postId: number, targetPosts?: INewslinePost[]): Observable<INewslinePost> {
    this._unpinPinnedPostLocallyIfExist();
    this._pinPostByIdLocally(postId, targetPosts);

    return this.updatePostByIdAndReplaceInPosts(postId, { pinned: true });
  }

  unpinPostByIdAndUpdatePostsState(postId: number): Observable<INewslinePost> {
    this._unpinPinnedPostLocallyIfExist();
    return this.updatePostByIdAndReplaceInPosts(postId, { pinned: false });
  }

  private _pinPostByIdLocally(postId: number, targetPosts?: INewslinePost[]) {
    const targetPostIdx = targetPosts?.findIndex((p) => p.id === postId);
    if (targetPosts && targetPostIdx !== -1) {
      const targetPost = targetPosts.splice(targetPostIdx, 1)[0];
      targetPost.pinned = true;
      targetPosts.unshift(targetPost);
      this.postsFilteredCount -= 1;
    }
  }

  private _unpinPinnedPostLocallyIfExist() {
    // const targetPostIdx = this.posts.findIndex((p) => p.pinned);
    // if (targetPostIdx !== -1) {
    //   const targetPost = this.posts.splice(targetPostIdx, 1)[0];
    //
    //   if (targetPost?.pinned) {
    //     targetPost.pinned = false;
    //     const targetPositionIdx = this.posts.findIndex((p) => p.created.isBefore(targetPost.created));
    //     this.postsFilteredCount += 1;
    //
    //     if (targetPositionIdx !== -1) {
    //       this.posts.splice(targetPositionIdx, 0, targetPost);
    //     } else {
    //       this.posts.push(targetPost);
    //     }
    //   }
    // }
  }

  private _getCreateRequestsForPostAttachments(
    targetPost: INewslinePost,
    images?: INewslinePostImageDto[],
    files?: IFileDto[],
    voteDto?: INewslinePostVoteDto,
  ) {
    let imagesRequests: Observable<INewslinePostImage>[] = [];
    if (images?.length) {
      imagesRequests = images.map((imageDto) => this._getCreateRequestForPostImage(imageDto, targetPost));
    }

    let filesRequests: Observable<IFile>[] = [];
    if (files?.length) {
      filesRequests = files.map((fileDto) => this._getCreateRequestForPostFile(fileDto, targetPost));
    }

    const voteWithAnswersRequest: Observable<INewslinePostVote>[] = [];
    if (voteDto && voteDto?.answers?.length) {
      voteWithAnswersRequest.push(this._getCreateRequestForPostVoteWithAnswers(voteDto, targetPost));
    }

    return [...imagesRequests, ...filesRequests, ...voteWithAnswersRequest];
  }

  private _getUpdateRequestsForPostAttachments(targetPost: INewslinePost, voteDto?: INewslinePostVoteDto) {
    const voteWithAnswersRequest: Observable<INewslinePostVote>[] = [];
    if (voteDto && voteDto?.answers?.length) {
      voteWithAnswersRequest.push(this._getUpdateRequestForPostVoteWithAnswers(voteDto, targetPost));
    }

    return [...voteWithAnswersRequest];
  }

  private _getDeleteRequestsForPostAttachments(
    targetPost: INewslinePost,
    images?: INewslinePostImageDto[],
    files?: IFileDto[],
    voteDto?: INewslinePostVoteDto,
    answers?: INewslinePostVoteAnswerDto[],
  ) {
    const deleteRequests: Observable<void | void[]>[] = [];
    if (images?.length) {
      deleteRequests.push(...images.map((imageDto) => this._getDeleteRequestForPostImage(imageDto.id, targetPost)));
    }

    if (files?.length) {
      deleteRequests.push(...files.map((fileDto) => this._getDeleteRequestForPostFile(fileDto.id, targetPost)));
    }

    if (voteDto) {
      deleteRequests.push(this._getDeleteRequesForPostVote(voteDto, targetPost));
    } else if (answers?.length) {
      deleteRequests.push(
        ...answers.map((answersDto) => this._getDeleteRequestForPostVoteAnswer(answersDto.id, targetPost.vote)),
      );
    }

    return deleteRequests;
  }

  private _getCreateRequestForPostImage(
    imageDto: INewslinePostImageDto,
    targetPost: INewslinePost,
  ): Observable<INewslinePostImage> {
    return this.newslinePostImageService.createImageAndAddToPost(
      {
        image: imageDto.image,
        post: targetPost.id,
      },
      targetPost,
    );
  }

  private _getDeleteRequestForPostImage(imageId: number, targetPost: INewslinePost): Observable<void> {
    return this.newslinePostImageService.deleteImageByIdAndRemoveFromPost(imageId, targetPost);
  }

  private _getCreateRequestForPostFile(fileDto: IFileDto, targetPost: INewslinePost): Observable<IFile> {
    return this.newslinePostFileService.createFileAndAddToPost(
      {
        file: fileDto.file,
        post: targetPost.id,
      },
      targetPost,
    );
  }

  private _getDeleteRequestForPostFile(fileId: number, targetPost: INewslinePost): Observable<void> {
    return this.newslinePostFileService.deleteFileByIdAndRemoveFromPost(fileId, targetPost);
  }

  private _getCreateRequestForPostVoteWithAnswers(
    voteDto: INewslinePostVoteDto,
    targetPost: INewslinePost,
  ): Observable<INewslinePostVote> {
    return this.newslinePostVoteService
      .createVoteAndAddToPost(
        {
          question: voteDto.question,
          post: targetPost.id,
          is_results_hidden: voteDto.is_results_hidden,
          type: voteDto.type,
          allow_members_answers: voteDto.allow_members_answers,
        },
        targetPost,
      )
      .pipe(
        mergeMap((createdVote) => {
          const answersRequests: Observable<INewslinePostVoteAnswer>[] = [];
          const targetVote = targetPost ? targetPost.vote : createdVote;

          voteDto.answers.map((answerDto) => {
            answersRequests.push(this._getCreateRequestForPostVoteAnswer(answerDto, targetVote));
          });
          return forkJoin(answersRequests).pipe(map(() => targetVote));
        }),
      );
  }

  private _getUpdateRequestForPostVoteWithAnswers(
    voteDto: INewslinePostVoteDto,
    targetPost: INewslinePost,
  ): Observable<INewslinePostVote> {
    return this.newslinePostVoteService
      .updateVoteByIdAndReplaceInPost(
        voteDto.id,
        {
          question: voteDto.question,
          is_results_hidden: voteDto.is_results_hidden,
        },
        targetPost,
      )
      .pipe(
        mergeMap((createdVote) => {
          const answersRequests: Observable<INewslinePostVoteAnswer>[] = [];
          const targetVote = targetPost ? targetPost.vote : Object.assign(targetPost.vote, createdVote);

          voteDto.answers.map((answerDto) => {
            if (answerDto.id) {
              answersRequests.push(this._getUpdateRequestForPostVoteAnswer(answerDto, targetVote));
            } else {
              answersRequests.push(this._getCreateRequestForPostVoteAnswer(answerDto, targetVote));
            }
          });
          return forkJoin(answersRequests).pipe(map(() => targetVote));
        }),
      );
  }

  private _getDeleteRequesForPostVote(voteDto: INewslinePostVoteDto, targetPost: INewslinePost): Observable<void> {
    return this.newslinePostVoteService.deleteAnswerByIdAndRemoveFromVote(voteDto.id, targetPost);
  }

  private _getCreateRequestForPostVoteAnswer(
    answerDto: INewslinePostVoteAnswerDto,
    targetVote: INewslinePostVote,
  ): Observable<INewslinePostVoteAnswer> {
    return this.newslinePostVoteAnswerService.createAnswerAndAddToVote(
      {
        answer: answerDto.answer,
        poll: targetVote.id,
      },
      targetVote,
    );
  }

  private _getUpdateRequestForPostVoteAnswer(
    answerDto: INewslinePostVoteAnswerDto,
    targetVote: INewslinePostVote,
  ): Observable<INewslinePostVoteAnswer> {
    return this.newslinePostVoteAnswerService.updateAnswerByIdAndReplaceInVote(
      answerDto.id,
      {
        answer: answerDto.answer,
      },
      targetVote,
    );
  }

  private _getDeleteRequestForPostVoteAnswer(answerId: number, targetVote: INewslinePostVote): Observable<void> {
    return this.newslinePostVoteAnswerService.deleteAnswerByIdAndRemoveFromVote(answerId, targetVote);
  }

  private _transformPostDtoToPostView(postDto: INewslinePostDto): INewslinePost {
    let postView: Partial<INewslinePost> = {};

    postView.created = this.dateService.transfromStringToMoment(postDto.created);
    postView.updated = this.dateService.transfromStringToMoment(postDto.updated);

    postView.author = this.authService.transformUserShortDtoToUserShortView(postDto.author);
    postView.isLikedByCurrentUser = postDto.liked_users?.includes(this.authService.currentUser?.id);

    postView = Object.assign(postDto, postView);
    return postView as INewslinePost;
  }

  // get postsCountWithPinned(): number {
  //   return this.postsFilteredCount + (this.posts[0]?.pinned ? 1 : 0);
  // }
}
