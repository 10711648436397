import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeyValue } from '@shared/models/key-value.model';
import {
  INewslinePostDto,
  INewslinePostNew,
  INewslineStory,
} from '@shared/models/newsline/dto/newsline-post-dto.model';
import { ArrayPayload } from '@shared/models/payload.model';
import { Observable } from 'rxjs';
import { HttpService } from '../http/http.service';
import { QueryParams } from '@shared/models/query-params.model';
import { prepareQuery } from '@shared/utils/utils';


export interface INewslineTopic {
  id: number,
  type: { id: number, name: string, priority: number},
  posts_count: number,
  name: string,
  in_fast_filter: boolean,
  is_allowed_to_member: boolean,
  is_visible_in_main: boolean,
  is_visible_for_guests: boolean
}

@Injectable({
  providedIn: 'root',
})
export class NewslinePostHttpService {
  private readonly _apiPath = '/newsline/post/';
  private readonly _apiPathStory = '/newsline/stories/';
  private readonly _apiPathTopic = '/newsline/topic/';

  constructor(private http: HttpService) {}

  getNewslinePosts(params?: QueryParams): Observable<ArrayPayload<INewslinePostNew>> {
    return this.http.get<ArrayPayload<INewslinePostNew>>(this._apiPath, {
      params: prepareQuery(params),
    });
  }

  getNewslineTopics(params?: QueryParams): Observable<ArrayPayload<INewslineTopic>> {
    return this.http.get<ArrayPayload<INewslineTopic>>(this._apiPathTopic, {
      params: prepareQuery(params),
    });
  }

  patchPostMarkAsViewed(id: number): Observable<void> {
    return this.http.post<void>(`${this._apiPath}${id}/mark-as-viewed/`, null)
  }

  getPostLikes(id: number, params?: QueryParams): Observable<ArrayPayload<any>> {
    return this.http.get<ArrayPayload<any>>(`${this._apiPath}${id}/likes/`, { params: prepareQuery(params)});
  }

  likeNewslinePost(id: number): Observable<void> {
    return this.http.post<void>(`${this._apiPath}${id}/like/`, null);
  }

  unlikeNewslinePost(id: number): Observable<void> {
    return this.http.post<void>(`${this._apiPath}${id}/unlike/`, null);
  }

  getPosts(params?: KeyValue<string>, paramsString?: string): Observable<ArrayPayload<any>> {
    return this.http.get<ArrayPayload<any>>(this._apiPath, {
      params: params ? new HttpParams({ fromObject: params }) : new HttpParams({ fromString: paramsString }),
    });
  }

  postReportNewslinePost(id: number, report: {types: string[], comment?: string}): Observable<{types: string[], comment?: string}> {
    return this.http.post<{types: string[], comment?: string}>(`${this._apiPath}${id}/complaint/`, report);
  }



  getPostById(id: number): Observable<INewslinePostDto> {
    return this.http.get<INewslinePostDto>(`${this._apiPath}${id}/`);
  }

  getPostByIdNew(id: number): Observable<INewslinePostNew> {
    return this.http.get<INewslinePostNew>(`${this._apiPath}${id}/`);
  }

  createPost(post: Partial<INewslinePostNew>): Observable<INewslinePostNew> {
    return this.http.post<INewslinePostNew>(this._apiPath, post);
  }

  updatePostById(id: number, post: Partial<INewslinePostDto>): Observable<INewslinePostDto> {
    return this.http.patch<INewslinePostDto>(`${this._apiPath}${id}/`, post);
  }

  public deletePostById(id: number): Observable<void> {
    return this.http.delete<void>(`${this._apiPath}${id}/`);
  }

  likePostById(id: number): Observable<void> {
    return this.http.post<void>(`${this._apiPath}${id}/like/`, null);
  }

  unlikePostById(id: number): Observable<void> {
    return this.http.post<void>(`${this._apiPath}${id}/unlike/`, null);
  }

  public incrementSharingPost(id: number): Observable<void> {
    return this.http.post<void>(`${this._apiPath}${id}/mark-as-shared/`, null);
  }

  // ToDo Когда появится тикет на сторис, сделать отдельный сервис
  getStoryById(id: number): Observable<INewslineStory> {
    return this.http.get<INewslineStory>(`${this._apiPathStory}${id}/`);
  }


  getExternalLinkPreview(link: string): Observable<any> {
    const api = 'https://api.linkpreview.net/?key=f7c2fd8e85020a968ec75144209fecd2&q=' + link;

    return this.http.getExternal(api);
  }
}
