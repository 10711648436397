<div
  class="likes-dialog-container"
  infiniteScroll
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="500"
  (scrolled)="onScroll()"
  [scrollWindow]="false"
>
  <div>
    <kp-popup-header>
      Оценили
      <span class="likes-dialog-container__header-count">
        {{ likesCount }}
      </span>
    </kp-popup-header>

    <div class="liked-users-container">
      <div class="liked-users-container__popup" *ngFor="let item of previewLikesArr">
        <a
          class="liked-users-container__popup--display-contents"
          target="_blank"
          rel="noopener noreferrer nofollow"
          [routerLink]="['/profile/' + item.id]"
        >
          <kp-user-avatar
            [avatarSize]="36"
            [avatarUrl]="item.avatar"
            [username]="item.first_name + ' ' + item.last_name"
          ></kp-user-avatar>

          <div>{{ item.first_name + ' ' + item.last_name }}</div>
        </a>
      </div>
    </div>
  </div>
</div>
